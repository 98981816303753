import { motion } from "framer-motion";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export const ScrollIntoView = ({
  persist = false,
  className,
  children,
}: PropsWithChildren<{ className?: string; persist?: boolean }>) => {
  const { ref, inView } = useInView();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (inView === true) {
      setVisible(true);
    } else if (inView === visible && !persist) {
      setVisible(false);
    }
  }, [inView]);

  return (
    <motion.div
      ref={ref}
      className={className}
      animate={visible ? "visible" : "hidden"}
      initial="hidden"
      transition={{ duration: 2 }}
      variants={{
        visible: {
          x: 0,
          opacity: 1,
        },
        hidden: { x: 200, opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
};
