import React from "react";
import { useCookies } from "react-cookie";

export const BlockWithVideo = ({ title, body, videoUrl }: BlockWithVideo) => {
  const [{ cookie_consent }] = useCookies(["cookie_consent"]);

  return (
    <div>
      {title && <h4 className="text-white font-bold mb-6">{title}</h4>}
      <p>{body}</p>
      {videoUrl && cookie_consent && (
        <div className="video-container mt-6 lg:mt-14 lg:mb-10">
          <iframe typeof="text/html" height="720" src={videoUrl} frameBorder="0" allowFullScreen />
        </div>
      )}
    </div>
  );
};
