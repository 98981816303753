import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, GatsbyImageProps, StaticImage } from "gatsby-plugin-image";
import React from "react";
import { BlockWithVideo } from "../components/Block";
import { useCommonTranslations } from "../components/CommonTranslations";
import Layout from "../components/layout";
import { ScrollIntoView } from "../components/ScrollIntoView";
import Seo from "../components/seo";
import { useFilteredByLocale } from "../hooks";

export default function SergeysStory({ location }: { location: Location }) {
  const commonTranslations = useCommonTranslations();

  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title={commonTranslations.sergeysStory} />
      <Body />
    </Layout>
  );
}

const Body = () => {
  const { title, blocks, images } = useSergeyStory();

  return (
    <div className="w-full text-white min-h-screen relative">
      <StaticImage
        className="object-cover h-screen"
        src="../images/sergey-banner.png"
        style={{ position: "absolute", top: 0 }}
        alt="Sergey"
      />
      <div className="grid grid-cols-12 relative mb-14 overflow-hidden md:gap-2">
        <div className="col-span-10 col-start-2 text-black mt-[70vh] mb-4 pl-4">
          <h1 className="text-3xl md:text-5xl">{title}</h1>
          <h1 className="text-xl font-light md:text-3xl">1952-2017</h1>
        </div>
        <div className="col-span-10 col-start-2 md:col-span-7 md:col-start-2 lg:col-span-6 lg:col-start-2 bg-black rounded-lg p-4 md:p-8 flex flex-col space-y-8">
          {blocks.map((props) => (
            <BlockWithVideo key={props.title} {...props} />
          ))}
        </div>
        <div className="hidden md:col-span-3 md:flex lg:col-start-9 flex-col justify-between">
          {images?.map((url, i) => (
            <ScrollIntoView
              className={cx("flex h-full w-full items-center", {
                "justify-start": i % 2 !== 1,
                "justify-end": i % 2 === 1,
              })}
              key={i}
              persist
            >
              <GatsbyImage image={url} alt="" className="md:w-5/6 xl:w-2/3" />
            </ScrollIntoView>
          ))}
        </div>
      </div>
    </div>
  );
};

const useSergeyStory = (): Omit<SergeyStory, "images"> & { images: GatsbyImageProps["image"][] | null } => {
  const {
    allStrapiSergeysStory: { edges },
  } = useStaticQuery<{ allStrapiSergeysStory: EdgesQuery<{ node: SergeyStory }> }>(query);

  return useFilteredByLocale(
    edges.map(({ node }) => ({
      ...node,
      images: node.images.map(
        ({
          localFile: {
            childImageSharp: { gatsbyImageData },
          },
        }) => gatsbyImageData
      ),
    }))
  )[0];
};

const query = graphql`
  query SergeyStory {
    allStrapiSergeysStory {
      edges {
        node {
          title
          locale
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          blocks {
            body
            title
          }
        }
      }
    }
  }
`;
